
<template>
	<Menu actual="/escuelas" class="mt-6" />

	<div class="bg-white overflow-hidden">
		<div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
			<div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
			<div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
				<div>
					<h2 class="text-base text-morado font-semibold tracking-wide uppercase">Formación</h2>
					<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-cyan sm:text-4xl">Escuelas</h3>
				</div>
			</div>
			<div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
				<div class="relative lg:row-start-1 lg:col-start-2">
					<svg class="hidden lg:block absolute top-0 right-0 -mt-20 -mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
						<defs>
							<pattern id="de316486-4a29-4312-bdfc-fbce2132a2c1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
								<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
							</pattern>
						</defs>
						<rect width="404" height="384" fill="url(#de316486-4a29-4312-bdfc-fbce2132a2c1)" />
					</svg>
					<div class="relative text-base mx-auto max-w-prose lg:max-w-none">
						<figure>
							<div class="aspect-w-12 aspect-h-7 lg:aspect-none">
								<img class="rounded-lg shadow-lg object-cover object-center" :src="require('@/assets/escuelas/burbujas.jpg')" alt="Whitney leaning against a railing on a downtown street" width="1184" height="1376" />
							</div>

							<div class=" hidden lg:block">
								<img class="mt-16 rounded-md shadow-lg mx-auto" :src="require('@/assets/escuelas/plataforma.png')" alt="" />
							</div>

							<!-- <figcaption class="mt-3 flex text-sm text-gray-500">
								<CameraIcon class="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
								<span class="ml-2">Photograph by Marcus O’Leary</span>
							</figcaption> -->
						</figure>
					</div>
				</div>
				<div class="mt-8 lg:mt-0">
					<div class="text-base max-w-prose mx-auto lg:max-w-none">
						<p class="text-lg text-gray-500">La escuela juega un papel determinante en la formación de niñas y niños, ya que además de transmitir conocimientos, influye en la adquisición de valores y  actitudes; en los procesos de socialización, de comunicación y autonomía; así como en la  percepción y actitud hacia la sexualidad.</p>
					</div>
					<div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
						<!-- <p>No podemos escoger si educamos sexualmente  o no en la escuela,, ésta, simplemente ocurre  a través de la convivencia con maestros y compañeros, por lo que debemos aprovechar las horas que pasan en ella para brindar conocimientos necesarios con respecto al tema, al mismo tiempo que promover el desarrollo de habilidades que los ayuden a tomar decisiones acertadas y planear su vida. </p> -->
						<p>Actualmente es imposible controlar la información sexual que reciben niñas y niños, por lo que  no los podemos dejar solos y que aprendan como puedan,  es indispensable el trabajo conjunto  de padres-escuela en materia sexual, para guiarlos y acompañarlos desde edades tempranas, con información verdadera; con una auténtica disposición al diálogo y resolver todas sus dudas,  para que construyan una percepción positiva hacia la sexualidad.</p>
						<p>Para apoyar a las escuelas  en esta enorme labor, en Cuéntame Cómo, desarrollamos la plataforma con material didáctico sobre educación sexual integral  para edades de 6 a 12 años y prevención de abuso de 5 a 8 años, como una herramienta para abordar el tema  desde edades tempranas y continuar conforme avanzan de grado escolar, de tal manera que la escuela se convierta en un agente de cambio , donde sea seguro aprender, y niñas y niños tengan la suficiente confianza para plantear sus dudas y curiosidades sin ningún temor a ser reprendidos y estén rodeados de experiencias que los ayuden a construir una imagen positiva de sí mismos; del cuerpo, sus funciones y capacidades; establecer relaciones igualitarias, respetar las diferentes opiniones , eliminar roles estereotipados y  reconocerse como seres únicos y valiosos. </p>
						<p>Todos los materiales   están diseñados de acuerdo a los intereses y necesidades de cada edad. Cada módulo consta de dos elementos, un libro y el acceso a juegos interactivos.</p>
						<ul>
							<li>El libro descargable en cualquier dispositivo contiene la información que se debe dar a niñas y niños, es decir, es una guía de lo que el maestro debe decir en  clase con su grupo. También sirve de material de exposición para el maestro.</li>
							<li>Juegos interactivos:  son un instrumento innovador para motivar la participación del grupo, reforzar y evaluar  el aprendizaje en forma divertida.</li>
						</ul>
						
						
						
						<div class="text-morado font-semibold text-2xl mt-16">¿Cómo funciona la plataforma?</div>
						<ul>
							<li>La  escuela puede rentar la plataforma por un tiempo determinado desde 1 mes, 3, 6 ó 12 meses.</li>
							<li>Puede seleccionar el número de módulos que necesite.</li>
							<li>El maestro y todos los alumnos tienen acceso al material, sin limite  de uso, por el tiempo que se contrató.</li>
							<li>Al ser digital se puede involucrar a los padres en las sesiones.</li>
							<li>La inversión depende del número de módulos, tiempo que se rente, y número de alumnos.</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>


	<div class="py-6 xl:py-8 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
		<div class="max-w-max lg:max-w-7xl mx-auto">
			<div class="relative">
				<div class="relative md:bg-white md:p-6">
					<div class="lg:grid lg:grid-cols-2 lg:gap-6">
						<div class="prose prose-indigo text-gray-500 lg:max-w-none space-y-4">
							<div class="text-2xl font-semibold text-vino">Objetivos</div>
							<ul>
								<li>Utilizar el tiempo a favor, incluir la educación sexual desde la más temprana edad.</li>
								<li>Acabar con la resistencia a abordar estos temas  en las escuelas.</li>
								<li>Cumplir con el derecho de niñas y niños de recibir educación sexual integral.</li>
								<li>Brindar al maestro herramientas que lo ayuden a abordar la educación sexual desde una perspectiva natural y sana.</li>
								<li>Hablar del tema de acuerdo a la etapa que estén viviendo niñas y niños, para que resuelvan las inquietudes  propias de cada edad.</li>
								<li>Que la maestra/o aporte información científica y verdadera, dejando de lado la opinión personal.</li>
								<li>Abordar la sexualidad desde una perspectiva integral y no centrada en aspectos biológicos., para que puedan explorar sus valores, actitudes, y adquieran habilidades para encontrar alternativas responsables para decidir.</li>
							</ul>
						</div>
						<div class="mt-6 prose prose-indigo text-gray-500 lg:mt-0 space-y-4">
							<div class="text-2xl font-semibold text-azul">Ventajas</div>
							<ul>
								<li>Ofrecer un programa a largo plazo para garantizar la continuidad y optimizar el aprendizaje.</li>
								<li>Transformar el tema de sexualidad, en un tema del que sí se puede hablar.</li>
								<li>Desarrollar la confianza,  y mejorar la comunicación con padres, madres y otros adultos que intervienen en su educación.</li>
								<li>Aprovechar la disposición de niñas y niños a aprender dentro del ámbito escolar.</li>
								<li>El maestro/a no necesita  desarrollar material, cuenta con todo lo necesario para dar la clase, material de exposición, juegos para comprobar lo que han aprendido.</li>
								<li>Se fortalece el trabajo conjunto padres- maestros  con una misma dirección.</li>
								<li>Homogeneizar los conocimientos, todos tienen la misma información.</li>
								<li>Abordar estos tópicos de manera responsable, profesional y comprometida.</li>
							</ul>
						</div>
					</div>

					<div class="lg:grid lg:grid-cols-2 lg:gap-6 mt-12">
						<div class="prose prose-indigo text-gray-500 lg:max-w-none space-y-4">
							<div class="text-2xl font-semibold text-verde">Beneficios</div>
							<ul>
								<li>Abrir canales de comunicación con respecto al tema.</li>
								<li>Promover fuentes confiables de información.</li>
								<li>Contrarrestar la información nociva.</li>
								<li>Influir positivamente en la percepción de la sexualidad.</li>
								<li>Eliminar prejuicios y falsos conceptos.</li>
								<li>Promover la equidad de género.</li>
								<li>Alertarlos con respecto a problemas como abuso sexual.</li>
								<li>Fomentar una educación preventiva.</li>
								<li>Influir en la felicidad futura.</li>
							</ul>
						</div>
						<div class="mt-6 text-gray-500 space-y-8 text-base pt-12 ">
							<div class="text-base text-gray-500">Contáctanos para ofrecerte una solución a tu medida.</div>
							<div class="space-y-8">
								<div class="space-y-1">
									
									<div class="flex items-end space-x-3">
										<MailIcon class="w-6 h-6 text-vino" />
										<div>
											<div class="text-xs font-medium text-gray-500 ">ESCRÍBENOS</div>
											<a href="mailto:hola@cuentamecomo.mx" class="text-vino no-underline hover:underline font-medium">hola@cuentamecomo.mx</a>
										</div>
									</div>
								</div>

								<div class="space-y-1">
									
									<div class="flex items-end space-x-3">
										<PhoneIcon class="w-6 h-6 text-morado" />
										<div>
											<div class="text-xs font-medium text-gray-500 ">LLÁMANOS</div>
											<div class="font-medium text-morado">442 586 98 84</div>
										</div>
										
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  	</div>
</template>

<script>
import { MailIcon, PhoneIcon } from '@heroicons/vue/solid'
import Menu from '@/components/core/Menu'

export default {
	components: {
		MailIcon,
		PhoneIcon,
		Menu
	},
}
</script>